*, *::before, *::after {
    box-sizing: border-box;
}
html{
    scroll-behavior: smooth;
}
body {
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: subpixel-antialiased;
    font-family: myRobotoLight, sans-serif;
    margin: 0;
    line-height: 1.5;
}
svg{
    width: 100%!important;
    height: 100vh!important;
}
a{
    cursor: pointer;
    text-decoration: none;
}
a:link {
    text-decoration: none;
    color: #42A8D6;
}
@font-face {
    font-family: AnzeigenGroTOT;
    src: url(fonts/AnzeigenGroTOT.otf);
}
button:focus{
    outline: none;
}
label{
    display: inline;
    font-size: 20px;/*před zvětšováním nebylo*/
    margin-left: 0.2rem;
}
.generalTitleStyle{
    font-family: AnzeigenGroTOT;
    /*font-size: 40px; PŘED zvětšením*/
    font-size: 50px;
    color: #42A8D6;
    letter-spacing: 3px;
}
.generalSubTitleStyle{
    font-family: "Roboto Light", sans-serif;
    /*font-size: 26px; před zvětšením*/
    font-size: 31px;
    color: #42A8D6;
}
.generalParagStyle{
    font-family: "Roboto Light", sans-serif;
    /*font-size: 19px; před zvětšením*/
    font-size: 24px;
    color: #282c34;
}
.containerCenterFixed{
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}
.pageCon{
    position: absolute;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100%;
}
.backgroundDC{
    /*background-image: url("./images/Vykricniky_BG.svg"); malé vykřičníčky*/
    background-image: url("./images/Vykrickniky_BG_BIG.svg");
    width: 100%;
    height: 100vh;
    background-size: cover;
}
.backgroundES{
    /*background-image: url("./images/EmployeeSweets_BG.svg");staré background ES*/
    background-image: url("./images/EmployeeSweets_BG_BIG.svg");
    width: 100%;
    height: 100vh;
    background-size: cover;
}
.backgroundKW{
    background-image: url("./images/KeyWords_BG.svg");
    width: 100%;
    height: 100vh;
    background-size: cover;
}
.backgroundClients{
    /*background-image: url("./images/Client_BG.svg");staré background clients*/
    background-image: url("./images/Client_BG_Wave.svg");
    width: 100%;
    height: 100vh;
    background-size: cover;
}
.BP_BG{
    background-image: url("./images/BP_BG.svg");
    width: 100%;
    height: 100vh;
    background-size: cover;
}
.backgroundHistoryCorner{
    background-image: url("./images/HistoryCorners_BG.svg");
    width: 100%;
    height: 100vh;
    background-size: cover;
}
.backgroundHistoryCorner2{
    background-image: url("./images/HistoryCorners2_BG.svg");
    width: 100%;
    height: 100vh;
    background-size: cover;
    animation: fadeIn 1s ease-in-out;
}
.HR_BG{
    background-image: url("./images/HR_BG.svg");
    width: 100%;
    height: 100vh;
    background-size: cover;
}
.DevTeam_BG{
    background-image: url("./images/DevTeam_BG.svg");
    width: 100%;
    height: 100vh;
    background-size: cover;
}
.Kviz_BG{
    background-image: url("./images/BP_Kviz.svg");
    width: 100%;
    height: 100vh;
    background-size: cover;
}

/*BG animation*/
div .backgroundHistory{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 500px;
    background-image: url("./images/Wave1_BG.svg");
    background-size: 1000px 600px;
    z-index: 1000;
}
div .backgroundHistory.wave1{
    animation: animateWave 45s linear infinite;
}

@keyframes animateWave {
    0% {
        background-position-x: 0;
    }

    100%{
        background-position-x: 1000px;
    }
}

div .backgroundHistory2{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 500px;
    background-image: url("./images/Wave2_BG.svg");
    background-size: 1000px 600px;
    z-index: 999;
}
div .backgroundHistory2.wave2{
    animation: animateWave2 33s linear infinite;
}

@keyframes animateWave2 {
    0% {
        background-position-x: 0;
    }

    100%{
        background-position-x: 1000px;
    }
}
/*BG animation*/

.linkDC, .linkCareer{
    color: #42A8D6;
    /*font-size: 25px; před zvětšováním fontu*/
    font-size: 22px;
}
.tadaCustom{

    animation: tada 2s infinite;
}
.pageIn{
    animation: animHP 3s;
}
@keyframes animHP {
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(1);
    }
}
.it_sticker{
    width: 10%;
    margin: 0 auto;
}
.it_stickerBasics{
    /*width: 10%;*/
    position: relative;
    width:100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
}
.socialStickers{
    width: 55%;
}
.generalLogoStyle img{
    width: 80%;
    margin: 0 auto;
}
.logoBP{
    width: 20%;
}
/*.homePage{
    z-index: 12;
}*/
/*.enterBP{
    transition: all 0.7s ease-out;
    -moz-transition: all 0.7s ease-out;
    -o-transition: all 0.7s ease-out;
    -webkit-transition: all 0.7s ease-out;
}
.enterBP:hover{
    transform: scale(1.03) translate(-25px, 1px);
}*/
.centerText{
    top: 50%;
    left: 20%;
    transform: translate(-30%,-50%);
    -ms-transform: translate(-50%,-50%);
    position: absolute;
}
.BP_Manifest_Sticker{
    width: 100%;
}
.historyTitle{
    font-family: Aura, fantasy;
    font-size: 60px;
    color: #42A8D6;
    padding-top: 163px;
    padding-bottom: 30px;
}
.historySubTitle{
    font-family: "Roboto Light", serif;
    text-align: left;
    font-size: 40px!important;
    color: #42A8D6;
}
.bp_basics_Title{
    font-family: Aura, fantasy;
    font-size: 60px;
    color: #42A8D6;
    padding-bottom: 30px;
}
.bp_manifestStyle{
    width: 90%;
}
.bp_manifestCenter{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-100%, 20%);
}

@media (max-width: 1600px) {
    .bp_manifestCenter{
        transform: translate(-100%, 0);
    }
}

.manifestParag{
    font-family: Roboto, sans-serif;
    /*font-size: 19px; před zvětšením*/
    font-size: 24px;
    color: #282c34;
}
.EStitle{
    font-family: "Roboto Light", sans-serif;
    /*font-size: 26px; před zvětšním*/
    font-size: 31px;
    /*font-weight: bold;*/
    color: #42A8D6;
}
.PPtitle{
    font-family: "Roboto Light", sans-serif;
    /*font-size: 26px;*/
    font-size: 31px;
    color: #42A8D6;
}
.ESDBsticker, .ESBTCsticker, .ESstickerNWO, .ESstickerMG{
    margin-top:10%;/*po zvětšení fotnu*/
    width: 75%;
}
.ESsticker, .ESstickerStylish{
    /*width: 60%;původně*/
    width: 45%;
}
.ESstickerStylish{
    position: relative;
    z-index: 99;
    cursor: pointer;
}
.ESstickerCareer{
    position: relative;
    z-index: 99;
    cursor: pointer;
}
.ESENGsticker{
    position: relative;
    z-index: 99;
    cursor: pointer;
}
.ESimproveSticker{
    position: relative;
    z-index: 99;
    cursor: pointer;
}
.stylishLi{
    position: relative;
    visibility: hidden;
    z-index: -20;
}
.careerLi{
    position: relative;
    visibility: hidden;
    z-index: -20;
}
.ESENGli{
    position: relative;
    visibility: hidden;
    z-index: -20;
}
.ESimproveLi{
    position: relative;
    visibility: hidden;
    z-index: -20;
}
.principlesPLul{
    font-size: 24px;/*před zvětšováním nebyl*/
    visibility: hidden;
}
.keyWordsUL{
    padding-left: 15px;
}
/*Test KeyWords*/
.keyWordsUlHide, .interniVyvojUlHide, .bbcUlHide, .ptpUlHide, .workUlHide, .btchUlHide{
    visibility: hidden;
}
.BTCHbutton, .interniVyvojBTN, .bbcBTN, .ptpBTN, .workBTN, .btchBTN{
    cursor: pointer;
}

.BTCHbutton:hover, .interniVyvojBTN:hover, .bbcBTN:hover, .ptpBTN:hover, .workBTN:hover, .btchBTN:hover{
    text-decoration: underline;
}
/*Test KeyWords*/
.careerLiSecond{
    position: relative;
    visibility: hidden;
    /*z-index: -20; nelze kliknout na odkaz*/
}
.principlesPlButton{
    background-color: #42A8D6;
    color: #ffffff;
    border: none;
    border-radius: 3px!important;
    padding: 10px 20px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-weight: 400;
    cursor: pointer;

}
ul {
    list-style: none;
}

/*ul li::before { odendání teček, odendá je to všude
    content: "\2022";
    color: #42A8D6;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}*/
.ESparagStyleNWO, .principlesCenteredDC, .imgCenteredPL, .imgCenteredDB, .imgCenteredBTC, .ESparagStyleBTC, .ESparagStyleDB{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.ESparagStyleTitle{
    position: relative;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.ESparagStyle{
    font-family: Roboto, sans-serif;
    /*font-size: 19px;před zvětšením*/
    font-size: 24px;
    color: #282c34;
    margin-top: 25px;
}
.stickerPrinciples{
    width: 25%;
}
.stickerPrinciplesDC{
    width: 20%;
}
.principlesParagStyle{
    font-family: Roboto, sans-serif;
    /*font-size: 17px; před zvětšením*/
    font-size: 22px;
    color: #282c34;
    margin-top: 25px;
    letter-spacing: 1px;
}
.benefitPrinciples{
    width: 75%;
    transform: translateY(100px);
}
.benefitPrinciplesMiddle{
    width: 75%;
    transform: translateY(50px);
}
.benefitPrinciplesMiddleDC, .benefitPrinciplesMiddlePL{
    margin-top: 10%;
    width: 75%;
}
.buttonBasicsGrid{
    position: relative;
    top: 50%;
    left: 0;
    transform: translate(-25%, -50%);
}

@media (min-width:1500px) and (max-width: 1600px) {
    svg{
        /*height: 780px!important;*/
        height: 100vh   ;
    }
}

.enterBP {
    background: black;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    margin: 10px;
    height: 20px;
    width: 20px;
    /*transform: scale(1.03) translate(-25px, 1px);*/
    /*animation: pulse 2s infinite;*/
}

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1.01)  translate(-8px, 0);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

/*.nextButton{
    border: 0;
    width: 2%;
    transform: rotate(0deg);
    position: absolute;
    bottom: 10px;
    left: 0%;
    !*transform: translate(-50%,-50%);*!
    !*top: 50%;*!
    height: 40px!important;
    background: #42A8D6;
    border-radius: 25px;
}*/
#arrow-forward{
    position: absolute;
    /*bottom: 30px;*/
    height: 40px!important;
}
.paragraphArrow{
    display: inline;
}
.nextButton{
    border: 0;
    width: 100px;
    position: absolute;
    display: inline;
    background: transparent;
}
.nextButtonManifest{
    position: relative;
    top: 50%;
    left: 0;
    transform: translate(-25%, -50%);
}

.backButtonManifest{
    position: relative;
    top: 50%;
    left: 0;
    transform: translate(-25%, -50%);
}
.back-arrow{
    margin-top: 30px;
    transform: rotate(180deg);
}
.nextButtonStylish{
    animation: mymove 3s infinite;
}
@keyframes mymove {
    0%   {top: 0;}
    50%  {top: 30px;}
    100% {top: 0;}
}
.nextButtonBasics{
    position: relative;
    top: 50%;
    left: 0;
    transform: translate(-100px, -50%);
}
.nextButtonPrinciples{
    /*transform: translate( 20px, -50px)*/
    position: relative;
    top: 50%;
    left: 0;
    transform: translate(-25%, -50%);
}
.nextButtonHistory{
    position: relative;
    top: 50%;
    left: 0;
    transform: translate(-25%, -50%);
}
.nextButtonNWO{
    position: relative;
    top: 50%;
    left: 0;
    transform: translate(-25%, -50%);
}
.nextButtonClients{
    position: relative;
    top: 30%;
    left: 0;
    transform: translate(-25%, -50%);
}
.nextButtonSocialMedia{
    position: relative;
    top: 50%;
    left: 0;
    transform: translate(-25%, -50%);
}
.nextButtonMouseMoveOut{
    animation: anim 3000ms;
}
.nextButtonHistoryMoveOut{
    animation: anim 2200ms;
}
.nextButtonTeamKeyMoveOut{
    animation: anim 3000ms;
}
.nextButtonBasicsMoveOut{
    animation: anim 3000ms;
}
.nextButtonSocialMediaMoveOut{
    animation: anim 3100ms;
}
.nextButtonManifestMoveOut{
    animation: anim 3000ms;
}


/*.nextButtonHistory{*/
/*    animation: mouseAnim 3s;*/
/*}*/

@keyframes anim {
    0% {
        left: 0;
    }
    100%{
        left: 250px;
    }

}
.exitButton{
    /*bottom: 30px;*/
    color: #42A8D6!important;
    font-size: 45px;
    transform: translate(0px, 70px);
    width: 0;
}
.fade-appear, .fade-enter{
    opacity: 0;
    z-index: 1;
}

.fade-appear-active, .fade-enter.fade-enter-active{
    opacity: 1;
    transition: opacity 300ms linear 150ms;
}

.fade-exit{
    opacity: 1;
}
.fade-exit.fade-exit-active{
    opacity: 0;
    transition: opacity 150ms linear;
}

.buttonQForm{
    background-color: #42A8D6;
    color: #ffffff;
    border: none;
    border-radius: 3px!important;
    padding: 16px 40px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-weight: 400;
    cursor: pointer;
}
.buttonQForm:hover{
    color: white !important;
    background-color: #333333!important;
    animation: pulse-blue 1750ms infinite;
}

.fadeInCustom{
    animation: fadeIn 2s;
}
.fadeInCustomLong{
    animation: fadeIn 3s;
}
.fadeOutCustom{
    animation: fadeOut 2s;
}
.fadeOutUpCustom{
    animation: fadeOutUp 2s;
}
.fadeInDownCustom{
    animation: fadeInDown 2s;
}
.fadeInUpCustom{
    animation: fadeInUp 2s;
}
.fadeInLeftCustom{
    animation: fadeInLeft 2s;
}
.fadeInRightCustom{
    animation: fadeInRight 2s;
}
.rollCustom{
    animation: rollIn 2s;
}
.pulseCustom{
    animation: pulse 2s infinite;
}

#buttonIdStylish{
    transform: translate(-45px,-100px);
}
.buttonStylish{
    animation: fadeDownArrow 2s;
}
@keyframes fadeDownArrow {
    0% {
        transform: translate(-45px,-100px);
        opacity: 1;
    }
    100% {
        transform: translate(-45px,0);
        opacity: 0;
    }
}

/**
* FadeIn Keyframes Animation
*/
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

/*Flip Card*/
.flip-card {
    background-color: transparent;
    width: 300px;
    height: 418px;
    perspective: 1000px;
    border-radius: 250px;
}

.flip-card-inner {
    position: relative;
    width: 300px;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    /*box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
    border-radius: 250px;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 300px;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 250px;
}

.flip-card-front {
    /*background-color: #bbb;*/
    color: black;
    border-radius: 250px;
}

.flip-card-back {
    background-color: #2980b9;
    color: white;
    transform: rotateY(180deg);
    border-radius: 250px;
}

.mainCharFlip{
    width: 300px;
    height: 418px;
    border-radius: 250px;
}
.flipCardBackPadding{
    padding-top: 60px;
}
.titlePartners{
    font-size: 30px;
}
.sekcePartnersPosition{
    transform: translateY(180px);
}
.customHrCharacters{
    transform: translateY(166px);
    width: 35%;
    border-top: 2px solid #42A8D6;
}
.centerIMG{
    text-align: -webkit-center;
}
/*Flip Card*/

.formCenter{
    margin: 0 auto;
    padding: 5px;
}

.client_logo{
    filter: grayscale(100%);
    transition: 0.3s;
}

.client_logo:hover{
    filter: none;
    transform: scale(1.1);
    transition-duration: 0.3s;
}

.basicText {
    font-size: 20px;
}

.keywords-border{
    width: 60%;
    border-top: 2px solid #42A8D6;
}

@media screen and (max-width: 1400px){
    .generalSubTitleStyle{
        font-family: "Roboto Light", sans-serif;
        font-size: 26px;
        color: #42A8D6;
    }

    .generalParagStyle{
        font-family: "Roboto Light",sans-serif;
        font-size: 19px;
        color: #282c34;
    }

    .generalTitleStyle{
        font-family: AnzeigenGroTOT;
        font-size: 40px;
        color: #42A8D6;
        letter-spacing: 3px;
    }
    .manifestParag{
        font-family: Roboto, sans-serif;
        font-size: 19px;
        color: #282c34;
    }
    .EStitle{
        font-family: "Roboto Light",sans-serif;
        font-size: 26px;
        color: #42A8D6;
    }
    .ESparagStyle{
        font-family: Roboto,sans-serif;
        font-size: 19px;
        color: #282c34;
        margin-top: 15px;
    }
    .principlesParagStyle{
        font-family: Roboto, sans-serif;
        font-size: 17px;
        color: #282c34;
        margin-top: 15px;
        letter-spacing: 1px;
    }
    .linkDC{
        font-size: 17px;
        color: #42A8D6;
    }
    .linkCareer{
        font-size: 19px;
        color: #42A8D6;
    }
    .basicText{
        font-size: 17px;
    }
    .PPtitle{
        font-size: 26px;
        font-family: "Roboto Light", sans-serif;
        color: #42A8D6;
    }
}